import { getTotalByKey, covertUtcToRestaurantDateTime } from '@/common/utility';
import { styled } from '@mui/material/styles';
import { Box, Table, TableCell, TableRow } from '@mui/material';
import { getCurrencyPrecision } from '@/services/utils/k_currency';
import { IPaymentRecord, ITransaction, PaymentRecordType } from '@/views/Payments';
import { IRestaurant } from '@/views/OrdersTableView/types';

type Props = {
    payment: ITransaction;
    restaurant: IRestaurant | null;
    currencySymbol: string;
    currencyCode: string;
    showTip: boolean;
    t?: any;
};

export const CustomCell = styled(TableCell)(() => ({
    padding: '0 5px 0 5px',
    fontSize: '10px',
    border: 'none',
    borderBottom: 'none',
}));

export const CustomHeadingCell = styled(TableCell)(() => ({
    padding: 'unset',
    paddingLeft: '5px',
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: '#e0d6ff',
    borderBottom: 'none',
}));

export const CustomHeaderBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#7D00D4',
    justifyContent: 'space-between',
    marginBottom: '4px',
}));

export default function PaymentReceipt({ payment, restaurant, currencySymbol, currencyCode, showTip, t }: Props) {
    const getTypeName = (type?: PaymentRecordType) => {
        switch (type) {
            default:
            case PaymentRecordType.User:
            case PaymentRecordType.NonQlubUser:
                return t('Bill Payment');
            case PaymentRecordType.Discount:
                return t('Discount Payment');
            case PaymentRecordType.Voucher:
                return t('Voucher Payment');
            case PaymentRecordType.Refund:
                return t('Refund Payment');
            case PaymentRecordType.Loyalty:
                return t('Loyalty Payment');
        }
    };
    return (
        <Box style={{ display: 'none', fontFamily: 'RobotoMono' }}>
            <Box
                id={`${payment.restaurant_unique}-${payment.id}`}
                style={{
                    width: '31.25rem',
                }}
            >
                <CustomHeaderBox>
                    <div>{t('Payment Receipt')}</div>
                    <div>
                        <img src="/logo.png" alt="Qlub logo" height="20" />
                    </div>
                </CustomHeaderBox>
                <Table>
                    <TableRow>
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                                border: '0.5px solid black',
                            }}
                        >
                            {t('Restaurant Name')}
                        </CustomCell>
                        <CustomCell
                            colSpan={2}
                            align="center"
                            style={{
                                border: '0.5px solid black',
                            }}
                        >
                            {restaurant?.name}
                        </CustomCell>
                    </TableRow>
                    <TableRow>
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                                border: '0.5px solid black',
                            }}
                        >
                            {t('Restaurant Address')}
                        </CustomCell>
                        <CustomCell
                            colSpan={2}
                            align="center"
                            style={{
                                border: '0.5px solid black',
                            }}
                        >
                            {restaurant?.address1}
                        </CustomCell>
                    </TableRow>
                    <TableRow>
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                                border: '0.5px solid black',
                            }}
                        >
                            {t('OrderID')}
                        </CustomCell>
                        <CustomCell
                            colSpan={2}
                            align="center"
                            style={{
                                border: '0.5px solid black',
                            }}
                        >
                            {payment.order_id}
                        </CustomCell>
                    </TableRow>
                    <TableRow>
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                                border: '0.5px solid black',
                            }}
                        >
                            {t('Date')}
                        </CustomCell>
                        <CustomCell colSpan={2} align="center" style={{ border: '0.5px solid black' }}>
                            {covertUtcToRestaurantDateTime(payment.created_at, 'MM / DD / YYYY HH : mm a')}
                        </CustomCell>
                    </TableRow>
                </Table>
                <Table
                    style={{
                        marginTop: '15px',
                        border: 'none',
                    }}
                >
                    <TableRow
                        style={{
                            height: '30px',
                        }}
                    >
                        <CustomHeadingCell colSpan={4}>{t('Order Details')}</CustomHeadingCell>
                    </TableRow>
                    <TableRow>
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                                width: '45%',
                            }}
                            align="left"
                        >
                            {t('Item')}
                        </CustomCell>
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                            }}
                            align="center"
                        >
                            {t('QTY')}
                        </CustomCell>
                        <CustomCell />
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                            }}
                            align="right"
                        >
                            {t('Price')}
                        </CustomCell>
                    </TableRow>
                    {payment.order_data && payment.order_data.items
                        ? payment?.order_data.items.map((i, idx) => (
                              <TableRow key={`${i.title}_${idx}`}>
                                  <CustomCell>{i.title}</CustomCell>
                                  <CustomCell align="center">{i.qty}</CustomCell>
                                  <CustomCell />
                                  <CustomCell align="right">
                                      {`${currencySymbol} `}
                                      {parseFloat(i.unitPrice || '0').toFixed(getCurrencyPrecision(currencyCode))}
                                  </CustomCell>
                              </TableRow>
                          ))
                        : null}
                    <TableRow>
                        <CustomCell colSpan={4}>&nbsp;</CustomCell>
                    </TableRow>
                </Table>
                <Table>
                    <TableRow
                        style={{
                            height: '30px',
                        }}
                    >
                        <CustomHeadingCell colSpan={5}>{t('Payment Details')}</CustomHeadingCell>
                    </TableRow>
                    <TableRow>
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                            }}
                            align="left"
                        >
                            {t('Time')}
                        </CustomCell>
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                            }}
                            align="left"
                        >
                            {t('Payment Status')}
                        </CustomCell>
                        {showTip ? (
                            <CustomCell
                                style={{
                                    fontWeight: 'bold',
                                }}
                                align="center"
                            >
                                {t('Tips')}
                            </CustomCell>
                        ) : (
                            <CustomCell />
                        )}
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                            }}
                            align="right"
                        >
                            {t('Total')}
                        </CustomCell>
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                            }}
                            align="right"
                        >
                            {t('Customer Paid')}
                        </CustomCell>
                    </TableRow>
                    {payment?.paymentRecord &&
                        payment?.paymentRecord?.map((r, idx) => {
                            if (r?.status === 'accepted' || r?.status === 'refunded') {
                                return (
                                    <TableRow key={idx}>
                                        <CustomCell align="left">
                                            {covertUtcToRestaurantDateTime(r.created_at, 'HH : mm')}
                                        </CustomCell>
                                        <CustomCell align="left">{getTypeName(r.type)}</CustomCell>
                                        {showTip ? (
                                            <CustomCell align="center">
                                                {`${currencySymbol} `}
                                                {parseFloat(r.tip_amount || '0').toFixed(
                                                    getCurrencyPrecision(currencyCode),
                                                )}
                                            </CustomCell>
                                        ) : (
                                            <CustomCell />
                                        )}
                                        <CustomCell align="right">
                                            {`${currencySymbol} `}
                                            {parseFloat(r.paid_amount || '0').toFixed(
                                                getCurrencyPrecision(currencyCode),
                                            )}
                                        </CustomCell>
                                        <CustomCell align="right">
                                            {`${currencySymbol} `}
                                            {parseFloat(r.paid_amount || '0') + parseFloat(r.tip_amount || '0')}
                                        </CustomCell>
                                    </TableRow>
                                );
                            }
                            return null;
                        })}
                    <TableRow>
                        <CustomCell colSpan={4}>&nbsp;</CustomCell>
                    </TableRow>
                </Table>
                <Table>
                    <TableRow
                        style={{
                            height: '30px',
                        }}
                    >
                        <CustomCell style={{ width: '50%' }}>&nbsp;</CustomCell>
                        <CustomHeadingCell colSpan={2} align="center" style={{ width: '50%' }}>
                            {t('Payment Summary')}
                        </CustomHeadingCell>
                    </TableRow>

                    <TableRow>
                        <CustomCell style={{ width: '50%' }}>&nbsp;</CustomCell>
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                                width: '25%',
                            }}
                            align="center"
                        >
                            {t('Bill Amount')}
                        </CustomCell>
                        <CustomCell align="center" style={{ width: '25%' }}>
                            {`${currencySymbol} `}
                            {parseFloat(payment.order_data?.amount || '0').toFixed(getCurrencyPrecision(currencyCode))}
                        </CustomCell>
                    </TableRow>

                    <TableRow>
                        <CustomCell style={{ width: '50%' }}>&nbsp;</CustomCell>
                        <CustomCell
                            style={{
                                fontWeight: 'bold',
                                width: '25%',
                            }}
                            align="center"
                        >
                            {t('Paid by Qlub_')}
                        </CustomCell>
                        <CustomCell align="center" style={{ width: '25%' }}>
                            {`${currencySymbol} `}
                            {(
                                getTotalByKey(
                                    payment.paymentRecord ? payment.paymentRecord : [],
                                    'paid_amount',
                                    (pr: IPaymentRecord) => pr.status === 'accepted',
                                ) -
                                getTotalByKey(
                                    payment.paymentRecord ? payment.paymentRecord : [],
                                    'paid_amount',
                                    (pr: IPaymentRecord) => pr.status === 'refunded',
                                )
                            ).toFixed(getCurrencyPrecision(currencyCode))}
                        </CustomCell>
                    </TableRow>

                    {showTip && (
                        <TableRow>
                            <CustomCell style={{ width: '50%' }}>&nbsp;</CustomCell>
                            <CustomCell
                                style={{
                                    fontWeight: 'bold',
                                    width: '25%',
                                }}
                                align="center"
                            >
                                {t('Tip Payment')}
                            </CustomCell>
                            <CustomCell align="center" style={{ width: '25%' }}>
                                {`${currencySymbol} `}
                                {getTotalByKey(
                                    payment.paymentRecord ? payment.paymentRecord : [],
                                    'tip_amount',
                                    (pr: IPaymentRecord) => pr.status === 'accepted',
                                ).toFixed(getCurrencyPrecision(currencyCode))}
                            </CustomCell>
                        </TableRow>
                    )}
                </Table>
            </Box>
        </Box>
    );
}
