import { useMemo } from 'react';

export interface ILocaleValue {
    locale: string;
    value: string;
}

export const parseJSON = (data: any) => {
    try {
        return typeof data === 'string' ? JSON.parse(data) : data;
    } catch {
        return undefined;
    }
};

export const convertMultiLocaleStringToObject = (
    value: string | Array<ILocaleValue>,
    defaultValue?: string,
): { [key: string]: string } => {
    if (!value) {
        return {
            default: defaultValue || '',
        };
    }
    try {
        const list: Array<ILocaleValue> = typeof value === 'string' ? parseJSON(value) : value;
        const localeMap = list.reduce<{ [key: string]: string }>((a, b) => {
            a[b.locale] = b.value;
            return a;
        }, {});
        if (!localeMap.hasOwnProperty('default')) {
            const def = list.find((o) => o.value !== '');
            if (def) {
                return {
                    default: def.value,
                    ...localeMap,
                };
            }
        }
        return localeMap;
    } catch {
        return {
            default: defaultValue || String(value),
        };
    }
};

export const validateMultiLocale = (value: string | Array<ILocaleValue>) => {
    const locales = convertMultiLocaleStringToObject(value);
    return Object.values(locales).some((o) => o);
};

export function MultiLocale({
    value,
    lang,
    Component,
    defaultValue,
}: {
    value: string | Array<ILocaleValue>;
    lang?: string | null;
    Component?: React.ElementType;
    defaultValue?: string;
}) {
    const defaultLang = 'en';
    const lng = lang || defaultLang;
    if (!value && defaultValue) {
        if (Component) {
            return <Component>{defaultValue}</Component>;
        }
        return defaultValue;
    }

    const locales = useMemo<any>(() => {
        return convertMultiLocaleStringToObject(value);
    }, [value]);

    const content = useMemo(() => {
        return locales[lng] || locales.default || '';
    }, [locales, lng]);
    if (!content) {
        return null;
    }
    if (Component) {
        return <Component>{content || defaultValue}</Component>;
    }
    return content || defaultValue;
}
