import { FC, useMemo } from 'react';
import { useTranslation } from '@/hooks/translations';
import { useRestaurantContext } from '@/contexts/restaurant';
import { DiningOptionEnum, IQsrOrder } from '@/views/QsrOrders/types';
import { MultiLocale, parseJSON } from '@/components/TicketModal/utils';
import { useLanguageQuery } from 'next-export-i18n';

import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
}

const QsrDineOption: FC<IProps> = ({ order }) => {
    const { t } = useTranslation('common');
    const { restaurant } = useRestaurantContext();
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';

    const text = useMemo(() => {
        const defaultTranslation = () => {
            switch (order.diningOption) {
                case DiningOptionEnum.DINING:
                    return t('Dine-in');
                case DiningOptionEnum.TAKEAWAY:
                    return t('Takeaway');
                case DiningOptionEnum.DRIVE_THROUGH:
                    return t('Drive-through');
                default:
                    return t('Unknown');
            }
        };

        const customText: Array<{
            key: string;
            value: string;
        }> = parseJSON(restaurant?.order_config?.diningOptionCustomText);
        if (!customText) {
            return defaultTranslation();
        }

        const dineOptionMap = customText.reduce<Record<string, any>>((a, { key, value }) => {
            a[key] = value;
            return a;
        }, {});

        return (
            <MultiLocale
                value={dineOptionMap[order.diningOption as string]}
                lang={lang}
                defaultValue={defaultTranslation()}
            />
        );
    }, [order.diningOption, lang]);

    if (!restaurant?.order_config?.diningOptionEnable) {
        return null;
    }

    return (
        <div className={styles.container}>
            <strong>{t('Dining Option:')}</strong> {text}
        </div>
    );
};

export default QsrDineOption;
